<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row class="d-flex flex-nowrap">
          <div style="color: #e33354" class="pa-2 mt-4">
            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              @click="handleNew"
              dense
            >
              {{ $t("MENU.NEW") }} {{ $t("MENU.POST") }}
            </v-btn>
          </div>
          <div class="pa-2">
            <v-switch
              @change="handleShowMyNews"
              :disabled="loadingTable"
              v-model="shortcut_last_updated_by_me"
              :label="$t('NEWS_SHORTCUTS.last_updated_by_me')"
            ></v-switch>
          </div>
          <div class="pa-2">
            <v-switch
              @change="handleShowDraft"
              :disabled="loadingTable"
              v-model="shortcut_draft"
              :label="$t('NEWS_SHORTCUTS.draft')"
            ></v-switch>
          </div>
          <div class="pa-2">
            <v-switch
              @change="handleShowDraft2"
              :disabled="loadingTable"
              v-model="shortcut_awaiting_acceptance"
              :label="$t('NEWS_SHORTCUTS.awaiting_acceptance')"
            ></v-switch>
          </div>
          <div class="pa-2">
            <v-switch
              @change="handleShowDraft3"
              :disabled="loadingTable"
              v-model="shortcut_published"
              :label="$t('NEWS_SHORTCUTS.published')"
            ></v-switch>
          </div>
          <div class="d-flex flex-row ml-auto">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Keresés a címekben"
              single-line
              hide-details
              @change="handleSearch"
              class="pb-5"
            ></v-text-field>
            <v-icon
              class="align-self-center mb-3 mx-3"
              color="primary"
              small
              @click="
                options.searchTerm = null;
                searchTerm = null;
                search = null;
                getDataFromApi();
              "
            >
              flaticon2-delete
            </v-icon>
          </div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row style="background-color: #f6f5f4">
          <v-col
            class="py-1"
            cols="12"
            sm="8"
            md="8"
            v-if="headerCategories[0]"
          >
            <p
              class="py-1 my-0 header-category"
              :class="{ active: !options.category_id }"
              @click="handleResetHeaderCategoryFilter"
            >
              MINDEN CIKK
            </p>
          </v-col>
          <v-col
            class="py-1"
            cols="12"
            sm="2"
            md="2"
            v-if="headerCategories[0]"
          >
            <p class="py-1 my-0">Események</p>
          </v-col>
          <v-col
            class="py-1"
            cols="12"
            sm="2"
            md="2"
            v-if="headerCategories[0]"
          >
            <p class="py-1 my-0"></p>
          </v-col>
        </v-row>
        <v-row class="mb-5" style="background-color: #fafafa">
          <v-col cols="12" sm="8" md="8" v-if="headerCategories[0]">
            <v-row>
              <v-col
                cols="12"
                sm="3"
                md="3"
                class="py-1 header-category"
                v-for="category in headerCategories[0].values"
                :key="category.id"
              >
                <span
                  @click="handleSelectHeaderCategory(category)"
                  :class="{ active: options.category_id == category.id }"
                  >{{ category.name }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            class="py-1"
            cols="12"
            sm="2"
            md="2"
            v-if="headerCategories[1]"
          >
            <span
              v-for="category in headerCategories[1].values"
              :key="category.id"
            >
              <p
                @click="handleSelectHeaderCategory(category)"
                class="py-1 my-0 header-category"
              >
                <span
                  @click="handleSelectHeaderCategory(category)"
                  :class="{ active: options.category_id == category.id }"
                  >{{ category.name }}
                </span>
              </p>
            </span>
          </v-col>
          <v-col
            class="py-1"
            cols="12"
            sm="2"
            md="2"
            v-if="headerCategories[2]"
          >
            <p
              v-for="category in headerCategories[2].values"
              :key="category.id"
              class="py-1 my-0 header-category"
            >
              <span
                @click="handleSelectHeaderCategory(category)"
                :class="{ active: options.category_id == category.id }"
                >{{ category.name }}
              </span>
            </p>
          </v-col>
        </v-row>
        <NewsModalForm
          :modalData="modalData"
          :permissions="permissions"
          :statuses="statuses"
          :endPoint="endPoint"
          :newsCategoryCollection="newsCategoryCollection"
          @closeModalForm="handleCloseModalForm"
          @saveModalForm="handleSaveModalForm"
        ></NewsModalForm>

        <v-spacer></v-spacer>
        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="newsCollection"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loadingTable"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [20, 50, 100],
              }"
              @click:row="handleClickItem"
            >
              <!-- <template #header="{ props: { headers } }">
                <thead class="v-data-table-header" ></thead>
              </template> -->

              <!-- <template v-slot:top>
                <v-toolbar flat>
                  <NewsModalForm
                    :modalData="modalData"
                    :permissions="permissions"
                    :statuses="statuses"
                    :endPoint="endPoint"
                    :newsCategoryCollection="newsCategoryCollection"
                    @closeModalForm="handleCloseModalForm"
                    @saveModalForm="handleSaveModalForm"
                  ></NewsModalForm>

                  <v-spacer></v-spacer>
                  <DeleteModalDialog
                    :dialogDelete="dialogDelete"
                    @closeDelete="handleCloseDelete"
                    @deleteItemConfirm="handleDeleteItemConfirm"
                  >
                  </DeleteModalDialog>
                </v-toolbar>
              </template> -->

              <template v-slot:item.name="{ item }">
                <b>
                  {{ item.name }}
                </b>
              </template>
              <template v-slot:item.tableCategories="{ item }">
                <span v-for="category in item.categories" :key="category.id">
                  {{ getNewsCategoryByID(category).name }}
                </span>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:item.updated_at="{ item }">
                {{ formatDate(item.updated_at) }}
              </template>
              <template v-slot:item.published_at="{ item }">
                {{ formatDate(item.published_at) }}
              </template>
              <template v-slot:item.user_id="{ item }">
                {{ getUserFullName(item.user_id) }}
              </template>
              <template v-slot:item.last_updated_by="{ item }">
                {{ getUserFullName(item.last_updated_by) }}
              </template>
              <template v-slot:item.status="{ item }">
                <span v-if="statuses && statuses.news && item.status">{{
                  $t("STATUSES." + statuses.news.news[+item.status].value)
                }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  color="primary"
                  v-if="permissionCan('delete')"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- <pre>{{ newsCollection }}</pre> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import NewsModalForm, { initialFormData } from "./NewsModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import ApiService from "@/core/services/api.service";

export const PERMISSION_TO = "news.news.";

const INITIAL_HEADER_CATEGORIES = [
  { id: 2, name: "Sportág", values: [] },
  { id: 1, name: "Esemény", values: [] },
  { id: 3, name: "Szakszemmel", values: [] },
];

import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "News",
  components: { NewsModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.POSTS"),
      search: "",
      routePath: "/cms/news/news/",
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }).lang,
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        // {
        //   text: this.$t("TABLE.translated"),
        //   value: "translated",
        //   width: "100px",
        //   sortable: false,
        // },
        // {
        //   text: this.$t("TABLE.created_at"),
        //   value: "created_at",
        // },
        {
          text: this.$t("TABLE.title"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("TABLE.categories"),
          value: "tableCategories",
          sortable: false,
        },
        { text: this.$t("TABLE.created_by"), value: "user_id" },
        {
          text: this.$t("TABLE.last_updated_by"),
          value: "last_updated_by",
          sortable: false,
        },

        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.updated_at"),
          value: "updated_at",
        },
        {
          text: this.$t("TABLE.published_at"),
          value: "published_at",
        },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      newsCollection: [],
      formValid: false,

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,

      totalItems: 0,
      options: {
        sortBy: ["updated_at"],
        sortDesc: [true],
        created_at_date_from: null,
        created_at_date_to: null,
        category_id: null,
        user_id: null,
        status: null,
        searchTerm: null,
        my_recently_modified_items: true,
      },

      date_picker_created_at_from: false,
      created_at_date_from: null,
      time_picker_created_at_from: false,
      created_at_time_from: null,

      date_picker_created_at_to: false,
      created_at_date_to: null,
      time_picker_created_at_to: false,
      created_at_time_to: null,

      shortcut_last_updated_by_me: true,
      shortcut_draft: false,
      shortcut_awaiting_acceptance: false,
      shortcut_published: false,

      headerCategories: [...INITIAL_HEADER_CATEGORIES],
    };
  },

  computed: {
    ...mapGetters([
      // "newsCollection",
      "newsCategoryCollection",
      "statuses",
      "getNewsCategoryByID",
      "userCollection",
      "getUserByID",
      "currentUser",
    ]),
    endPoint() {
      return "news/admin/" + SiteService.getActiveSiteId() + "/news/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },

    options: {
      handler() {
        if (!this.loadingTable) this.getDataFromApi();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["fetchNewsCategory", "fetchUser"]),

    getDataFromApi() {
      this.loadingTable = true;

      ApiService.post(
        "news/admin/" + SiteService.getActiveSiteId() + "/backend_items/",
        this.options
      )
        .then((data) => {
          this.newsCollection = data.data.collection.map((item) => {
            // item.created = item.created_at.substring(0, 16).replace("T", " ");

            if (item.translations) {
              let translations = item.translations[this.selectedLocale];
              if (translations) {
                Object.keys(translations).forEach((property) => {
                  item[property] =
                    item.translations[this.selectedLocale][property];
                });
                item.translated = true;
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            return item;
          });

          this.totalItems = data.data.totalItems;
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors.errors) {
                this.setError(field, errors.errors[field][0]);
              }
              console.log(this.messages);
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    handleSearch() {
      if (this.search.length > 2) {
        this.options.locale = this.selectedLocale;
        this.options.searchTerm = this.search;
        //reset shortcuts
        this.shortcut_last_updated_by_me = false;
        this.shortcut_draft = false;
        this.shortcut_awaiting_acceptance = false;
        this.shortcut_published = false;

        this.options.status = null;
        this.options.my_recently_modified_items = null;

        this.$set(this.options, "itemsPerPage", 100);
        this.getDataFromApi();
      }
    },

    fetchModel() {
      this.fetchUser();
      this.fetchNewsCategory().then(() => {
        this.setHeaderCategories();
        this.options.user_id = this.currentUser.id;
        this.getDataFromApi();
      });
    },

    formatDate(date) {
      var d = new Date(date);
      return d.toLocaleString("hu-HU");
    },
    getUserFullName(user_id) {
      let user = this.getUserByID(user_id);
      if (user) {
        return (
          user.surname +
          " " +
          user.christian_name1 +
          " " +
          (user.christian_name2 ?? "")
        );
      }
      return "";
    },

    handleShowMyNews(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.user_id = null;
      options.locale = this.selectedLocale;
      options.searchTerm = this.search;

      options.itemsPerPage = 50;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.my_recently_modified_items = state;
      if (state) {
        this.search = null;
        options.searchTerm = null;
        options.user_id = this.currentUser.id;
      }

      this.options = Object.assign({}, options);
      // this.getDataFromApi();
    },

    handleShowDraft(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 0;
        this.shortcut_awaiting_acceptance = false;
        this.shortcut_published = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      // this.getDataFromApi();
      this.options = Object.assign({}, options);
    },

    handleShowDraft2(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;

      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 1;
        this.shortcut_draft = false;
        this.shortcut_published = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      // this.getDataFromApi();
      this.options = Object.assign({}, options);
    },

    handleShowDraft3(state) {
      let options = Object.assign({}, this.options);
      options.page = 1;
      options.sortDesc = [true];
      options.sortBy = ["updated_at"];
      options.itemsPerPage = 50;
      if (state) {
        options.status = 2;
        this.shortcut_draft = false;
        this.shortcut_awaiting_acceptance = false;

        this.search = null;
        options.searchTerm = null;
      } else {
        options.status = null;
      }
      // this.getDataFromApi();
      this.options = Object.assign({}, options);
    },

    setHeaderCategories() {
      this.headerCategories.forEach((category) => {
        category.values = this.newsCategoryCollection
          .filter(
            (item) =>
              item.news_category_type_id == category.id &&
              item.in_admin_news_quick_menu
          )
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
      });
    },

    handleSelectHeaderCategory(category) {
      if (this.loadingTable) return null;
      this.options.sortDesc = [true];
      this.options.sortBy = ["updated_at"];
      this.options.itemsPerPage = 50;

      // this.options.status = null;
      // this.options.my_recently_modified_items = false;
      // this.shortcut_draft = false;
      // this.shortcut_awaiting_acceptance = false;
      // this.shortcut_published = false;

      this.search = null;
      this.options.searchTerm = null;

      this.options.category_id = category.id;
      this.getDataFromApi();
    },

    handleResetHeaderCategoryFilter() {
      if (this.loadingTable) return null;
      this.options.category_id = null;
      this.getDataFromApi();
    },
  },

  mounted() {
    this.loadingTable = true;

    this.fetchModel();
  },
};
</script>

<style scoped>
.header-category:hover {
  color: #e33354;
  cursor: pointer;
}

.active {
  font-weight: 600;
  color: #e33354;
}
</style>
