var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1550px","persistent":"","scrollable":"","retain-focus":false},model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":_vm.nameRules,"label":_vm.$t('FORM_INPUT_NAMES.title'),"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] = ''},"change":_vm.handleChangeName},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[(
                _vm.formModel.id && _vm.formModel.translations[_vm.selectedLocale.lang]
              )?_c('v-text-field',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORMS.slug'),"rules":_vm.nameRules,"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.slug']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.slug'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].slug),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "slug", $$v)},expression:"formModel.translations[selectedLocale.lang].slug"}}):_vm._e()],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{ref:"date_picker_published_at",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('FORMS.date_of_published_at'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.published_at_date),callback:function ($$v) {_vm.published_at_date=$$v},expression:"published_at_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_picker_published_at),callback:function ($$v) {_vm.date_picker_published_at=$$v},expression:"date_picker_published_at"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"hu-HU"},model:{value:(_vm.published_at_date),callback:function ($$v) {_vm.published_at_date=$$v},expression:"published_at_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date_picker_published_at = false}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.date_picker_published_at.save(_vm.published_at_date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-row mt-3",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{ref:"time_picker_published_at",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('FORMS.time_of_published_at'),"prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.published_at_time),callback:function ($$v) {_vm.published_at_time=$$v},expression:"published_at_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.time_picker_published_at),callback:function ($$v) {_vm.time_picker_published_at=$$v},expression:"time_picker_published_at"}},[(_vm.time_picker_published_at)?_c('v-time-picker',{attrs:{"disabled":!_vm.published_at_date,"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.time_picker_published_at.save(_vm.published_at_time)}},model:{value:(_vm.published_at_time),callback:function ($$v) {_vm.published_at_time=$$v},expression:"published_at_time"}}):_vm._e()],1),_c('v-icon',{staticClass:"align-self-center",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.published_at_time = null;
                _vm.published_at_date = null;}}},[_vm._v(" flaticon2-delete ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.newsCategoryCollection,"attach":"","chips":"","deletable-chips":"true","item-text":"name","item-value":"id","label":_vm.$t('MENU.NEWS_CATEGORIES'),"multiple":"","dense":""},on:{"change":_vm.handleChangeCategory},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":"bg-gray-100","close":""},on:{"click:close":function($event){return _vm.deleteChip(item, _vm.formModel.categories)}}},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.formModel.categories),callback:function ($$v) {_vm.$set(_vm.formModel, "categories", $$v)},expression:"formModel.categories"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.statuses.news)?_c('v-select',{attrs:{"items":_vm.statuses.news.news,"label":_vm.$t('FORMS.status'),"item-value":"key"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t("STATUSES." + data.item.value))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t("STATUSES." + data.item.value))+" ")]}}],null,false,29268476),model:{value:(_vm.formModel.status),callback:function ($$v) {_vm.$set(_vm.formModel, "status", $$v)},expression:"formModel.status"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.on_the_main_page')},model:{value:(_vm.formModel.on_the_main_page),callback:function ($$v) {_vm.$set(_vm.formModel, "on_the_main_page", $$v)},expression:"formModel.on_the_main_page"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.hovered_title')},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].hovered_title
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "hovered_title", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].hovered_title\n              "}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"11","sm":"2","md":"2"}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","sm":"5","md":"5"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.on_the_front_page
              )?_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":_vm.max_50,"label":_vm.$t('FORM_INPUT_NAMES.alternative_title')},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].alternative_title
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "alternative_title", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].alternative_title\n              "}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.on_the_front_page')},model:{value:(_vm.formModel.on_the_front_page),callback:function ($$v) {_vm.$set(_vm.formModel, "on_the_front_page", $$v)},expression:"formModel.on_the_front_page"}})],1),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.video_content')},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].video_content
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "video_content", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].video_content\n              "}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row mb-3",attrs:{"cols":"11","sm":"2","md":"2"}})],1),_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","sm":"5","md":"5"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'text',
                label: 'FORM_INPUT_NAMES.og_title',
              },"customFieldName":"og_title","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .og_title || ''},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.author_name'),"items":_vm.newsAuthorCollection,"item-text":"name","item-value":"id","clearable":"true"},model:{value:(_vm.formModel.news_author_id),callback:function ($$v) {_vm.$set(_vm.formModel, "news_author_id", $$v)},expression:"formModel.news_author_id"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-combobox',{attrs:{"items":_vm.computedTagCollection,"label":_vm.$t('FORM_INPUT_NAMES.tags'),"multiple":"","attach":"","chips":"","deletable-chips":"true","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"close":"","color":"bg-gray-100"},on:{"click:close":function($event){return _vm.deleteChipComboBox(
                      item,
                      _vm.formModel.translations[_vm.selectedLocale.lang].tags
                    )}}},[_vm._v(_vm._s(item))])]}}],null,false,3557542095),model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].tags),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "tags", $$v)},expression:"formModel.translations[selectedLocale.lang].tags"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"featured_image","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .featured_image || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  } ],
                selectButtonText: 'FORM_INPUT_NAMES.featured_image',
                resolution: '933x504',
                selectButtonTextNotTransalated: '',
                initialColumnClass:
                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"card_image","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .card_image || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  } ],
                selectButtonText: 'FORM_INPUT_NAMES.card_image',
                resolution: '297x160',
                initialColumnClass:
                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","xs":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"og_image","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .og_image || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                selectButtonText: 'FORM_INPUT_NAMES.og_image',
                resolution: '1200x630',
                initialColumnClass:
                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"leading_article_image","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .leading_article_image || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  } ],
                selectButtonText: 'FORM_INPUT_NAMES.leading_article_image',
                resolution: '618x464',
                selectButtonTextNotTransalated: '',
                initialColumnClass:
                  'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{staticClass:"mt-4",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.felcim'),"id":_vm.dynamicID},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].felcim),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "felcim", $$v)},expression:"formModel.translations[selectedLocale.lang].felcim"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-checkbox',{staticClass:"mt-7",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.rss_nso')},model:{value:(_vm.formModel.rss_nso),callback:function ($$v) {_vm.$set(_vm.formModel, "rss_nso", $$v)},expression:"formModel.rss_nso"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.rss_nso
              )?_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":_vm.max_70,"label":_vm.$t('FORM_INPUT_NAMES.rss_title') + ' ' + _vm.RSSLength,"id":_vm.dynamicID},on:{"input":_vm.calcRSSLength},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].rss_title),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "rss_title", $$v)},expression:"formModel.translations[selectedLocale.lang].rss_title"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"max-width":"785px"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-textarea',{attrs:{"outlined":"true","label":_vm.$t('FORMS.lead')},on:{"change":_vm.handleChangeLead},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].lead),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "lead", $$v)},expression:"formModel.translations[selectedLocale.lang].lead"}}):_vm._e(),(
                  _vm.messages['translations.' + _vm.selectedLocale.lang + '.lead']
                )?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".lead"])+" ")]):_vm._e()],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.intro'],"outlined":"true","rules":_vm.max_160,"label":_vm.$t('FORMS.intro_max_160') + ' ' + _vm.introLength},on:{"input":_vm.calcIntroLength},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].intro),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "intro", $$v)},expression:"formModel.translations[selectedLocale.lang].intro"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{
                type: 'textarea',
                label: 'FORM_INPUT_NAMES.og_description',
              },"customFieldName":"og_description","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .og_description || ''},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('v-select',{attrs:{"items":_vm.getBanner(3, this, 'news_top_right_banner_id'),"item-text":"name","item-value":"id","label":_vm.$t('FORM_INPUT_NAMES.news_top_right_banner'),"clearable":"true"},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .news_top_right_banner_id
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  , "news_top_right_banner_id", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].custom_fields\n                  .news_top_right_banner_id\n              "}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('v-select',{attrs:{"items":_vm.getBanner(3, this, 'news_bottom_right_banner_id'),"item-text":"name","item-value":"id","label":_vm.$t('FORM_INPUT_NAMES.news_bottom_right_banner'),"clearable":"true"},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .news_bottom_right_banner_id
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  , "news_bottom_right_banner_id", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].custom_fields\n                  .news_bottom_right_banner_id\n              "}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('v-select',{attrs:{"items":_vm.getBanner(2, this, 'news_under_lead_banner_id'),"item-text":"name","item-value":"id","label":_vm.$t('FORM_INPUT_NAMES.news_under_lead_banner'),"clearable":"true"},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .news_under_lead_banner_id
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  , "news_under_lead_banner_id", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].custom_fields\n                  .news_under_lead_banner_id\n              "}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"3","sm":"6","md":"3"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('v-select',{attrs:{"items":_vm.getBanner(2, this, 'news_bottom_banner_id'),"item-text":"name","item-value":"id","label":_vm.$t('FORM_INPUT_NAMES.news_bottom_banner'),"clearable":"true"},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .news_bottom_banner_id
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  , "news_bottom_banner_id", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].custom_fields\n                  .news_bottom_banner_id\n              "}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticStyle:{"max-width":"830px"}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.text"))+" "),(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"id":"cke","error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'],"config":_vm.editorConfig},on:{"input":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'] =
                    ''},"namespaceloaded":_vm.onNamespaceLoaded},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].text),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "text", $$v)},expression:"formModel.translations[selectedLocale.lang].text"}}):_vm._e(),(
                  _vm.messages['translations.' + _vm.selectedLocale.lang + '.text']
                )?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".text"])+" ")]):_vm._e()],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"gallery","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .gallery || '',"config":{
                type: 'media_selector',
                maxFiles: -1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'FORM_INPUT_NAMES.img_description',
                    type: 'text',
                  } ],
                selectButtonText: _vm.$t('FORM_INPUT_NAMES.gallery'),
                resolution: '933x504',
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{ type: 'text', label: 'FORMS.seo_title' },"customFieldName":"seoTitle","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .seoTitle},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('FORMS.seo_description')},model:{value:(
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .seoDescription
              ),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  , "seoDescription", $$v)},expression:"\n                formModel.translations[selectedLocale.lang].custom_fields\n                  .seoDescription\n              "}}):_vm._e()],1)],1),_c('v-divider')],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.handleSaveModalForm(false)}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e(),(
          _vm.formModel.status != 2 &&
          ((_vm.permissionCan('create') && !_vm.formModel.id) ||
            _vm.permissionCan('update'))
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalFormAndPreview}},[_vm._v(" Előnézet ")]):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }